/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import moment from 'moment';
import Button from '../../../components/buttons/default';
import LinkButton from '../../../components/buttons/link';
import PreviewButton from '../../../components/buttons/preview';
import Pagination from '../../../components/pagination';
import ResultCount from '../../../components/resultCount';
import ScrollToTop from '../../../components/scrollToTop';
import Spinner from '../../../components/spinner';
import Icon from '../../../components/viewers/icon';
import ImageViewer from '../../../components/viewers/image';
import LongTextView from '../../../components/viewers/longText';
import AwardList from '../../../components/table/awardList';
import DateList from '../../../components/table/dateList';
import DesignationList from '../../../components/table/designationList';
import PeopleList from '../../../components/table/peopleList';
import TagList from '../../../components/table/tagList';
import CategoryList from '../../../components/table/categoryList';
import LocationList from '../../../components/table/locationList';
import Playlist from '../../../components/table/playlist';
import PodcastList from '../../../components/table/podcastList';
import RelatedMapList from '../../../components/table/relatedMapList';
import RelatedPeopleList from '../../../components/table/relatedPeopleList';
import RelatedSiteList from '../../../components/table/relatedSiteList';
import ThemeList from '../../../components/table/themeList';
import TableHeader from '../../../components/tableHeader';
import { differenceBy } from 'lodash';
import MissingRequiredInfoList from '../../../components/table/missingInfo';
import { TABLE_ROWS, getCategoryName, newTempId } from '../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
const _api = require('../../../api');
const columns = require('../../../data/columns_site.json');

const FeaturedSites = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [keywordValue, setKeywordValue] = useState('');
  const [keyword, setKeyword] = useState('');
  const [label, setLabel] = useState([])
  const dispatch = useDispatch()
  const take = 50;
  const [page, setPage] = useState(1);;
  const [sort, setSort] = useState(columns[0]);
  const [paginationHits, setPaginationHits] = useState(0)
  const [spinner, showSpinner] = useState(false);
  let section = props.section
  const searchSite = useSelector((state) => state.search);
  let searchResult = searchSite.searchResult || []
  let selectedResult = searchSite.selectedResult?.filter((elem) => elem.entity_type === 'site') || []
  let sectionResult = searchSite[section]?.filter((elem) => elem.entity_type === 'site') || []
  let selectedEntity = [...sectionResult, ...selectedResult]
  const featured = useSelector((state) => state.featured);
  const [resultFilters, setResultFilters] = useState({
    live: searchSite.filterLive,
    category: searchSite.filterCategory,
    limit: searchSite.selectedLimit,
    nearBy: searchSite.nearByLocation,
    timeLine: searchSite.timeLine
  })
  let start_date = featured.start_date && JSON.parse(featured.start_date)
  let end_date = featured.end_date && JSON.parse(featured.end_date)
  let start = start_date ? moment(start_date).format("MMMM Do YYYY") : ''
  let end = end_date ? moment(end_date).format("MMMM Do YYYY") : ''
  useEffect(() => {
    search()
  }, [page, sort, keyword]);

  const search = async () => {
    let sortBoolean = true
    const params = {
      "bool": {
        "must": [
          {
            "match": {
              "deleted": "false"
            }
          },
          { "match": { "created_via": "ADMIN" } },

        ],

      }
    };
    if (keyword) {
      sortBoolean = false
      params.bool.must.push({
        "multi_match": {
          "query": keyword,
          "fields": [
            "name^3",
            "people.person_name^2",
            "locations.name",
            "location",
            "related_events.categories.category_name",
            "related_event_series.categories.category_name",
            "related_sites.categories.category_name",
            // "related_sites.date_count",
            "related_sites.tags.tag_name",
            "tags.tag_name",
            "podcasts.podcast_name",
            "podcasts.podcast_type",
            "type_name",
            "people.role_name",
          ],
          "fuzziness": 2,
          "prefix_length": 2
        }
      });
    }


    // if (props?.entityType) {
    //   params.bool.must[3] = {
    //     "bool": {
    //       "should": [
    //         {
    //           "match": {
    //             "type": props?.entityType
    //           }
    //         }
    //       ]
    //     }
    //   }
    // }


    if (resultFilters?.live) {

      params.bool.must[params.bool.must.length] = {
        "bool": {
          "should": [
            {
              "match": {
                "live": resultFilters.live
              }
            }
          ]
        }
      }
    }
    if (resultFilters?.category?.length > 0) {
      const shouldArray = resultFilters.category.map((ele) => ({
        "match": {
          "categories.category_name": ele
        }
      }));
      params.bool.must.push({
        "bool": {
          "should": shouldArray
        }
      });
    }
    // params.bool.must.push({
    //   "term": {
    //     "live": resultFilters.live
    //   }
    // });
    // }
    // if (resultFilters?.category?.length > 0) {
    //   params.bool.must.push({
    //     "terms": {
    //       "categories.category_name": 'Public Art'
    //     }
    //   });
    // }
    // if (resultFilters?.nearBy) {
    //   let latLng = `${searchSite.lat}, ${searchSite.lng}`
    //   params.aroundLatLng = latLng
    //   params.aroundRadius = 8047
    // }


    if (resultFilters?.nearBy) {
      params.bool.filter = {
        "geo_distance": {
          "distance": "8047m", // 5 miles in meters
          "_geoloc": {
            "lat": searchSite.lat,
            "lon": searchSite.lng
          }
        }
      };
    }

    if (resultFilters?.timeLine) {
      params.bool.must.push({
        "range": {
          "date_image_count": {
            "gt": 1
          }
        }
      });
    }
    const finalParams = {
      query: params
    }
    if (sortBoolean) {
      finalParams.sort = [{ "modified_date": { "order": "desc" } }]
    }
    handleSetKeywords(keyword)
    showSpinner(true)
    await _api.site.searchByElastic((page - 1) * 50, take, finalParams).then(results => {
      setPaginationHits(results?.hits?.total?.value);
      const mappedHits = results?.hits?.hits.map(hit => hit._source);
      const filteredArray = differenceBy(mappedHits, selectedEntity, 'id')
      dispatch({ type: 'search', payload: { ...searchSite, searchResult: filteredArray } })
      showSpinner(false)
    })
  }

  const handleSetKeywords = (keyword) => {
    let data = [];
    if (resultFilters?.category?.length > 0) resultFilters.category.forEach((elem) => data.push(`Category - ${elem}`))
    if (resultFilters?.nearBy) { data.push(`Selected map name - relevant sites within that area`) }
    if (resultFilters?.timeLine) { data.push(`Site with a Timeline`) }
    if (resultFilters?.live) data.push(`Live - Sites`)
    if (keyword) data.push(keyword)
    setLabel(data)
  }

  const handleDeleteLabel = (index) => {
    let data = label[index];
    if (data.includes('Category')) resultFilters.category = resultFilters.category.filter((elem) => elem !== data.split("- ").pop())
    if (data.includes(`Selected map name - relevant sites within that area`) && resultFilters.nearBy) resultFilters.nearBy = false
    if (data.includes('Live')) resultFilters.live = ''
    if (data.includes('Site with a Timeline') && resultFilters?.timeLine) { resultFilters.timeLine = false }
    if (data === keyword) setKeywordValue(''); setKeyword('')
    setResultFilters(resultFilters)
    search()
  }

  const updateLive = (id, live) => {
    showSpinner(true)
    let selectedSearchResult = searchResult.hits.filter((item) => item.id === id)
    let selectedResult = selectedSearchResult[0]
    let dateDescription = selectedResult.dates?.filter((e) => e.description !== '')
    let category = getCategoryName(selectedResult?.categories)
    if (live === true) {
      if (
        selectedResult?.name &&
        category &&
        selectedResult?.locations && selectedResult?.locations?.[0]?.address &&
        (selectedResult?.description || dateDescription?.length > 0) &&
        selectedResult?.image?.url
      ) {
        _api.site.updateLive(id, live).then(async (_) => {
          await search()
          swal({
            title: 'Site is Live!',
            icon: 'success',
            text: `${selectedResult?.name} is now Live!`,
            buttons: 'Ok',
          })
        })
      } else {
        swal({
          title: `${selectedResult.name} cannot go Live!`,
          icon: 'error',
          text: `Please fill the mandatory fields:
          ${selectedResult?.name ? '' : 'Title is missing.'}
          ${category ? '' : 'Category is missing.'} 
          ${selectedResult?.locations && selectedResult?.locations?.[0] && selectedResult?.locations?.[0]?.address ? '' : 'Location is missing.'} 
          ${(selectedResult?.description || dateDescription?.length > 0) ? '' : 'Description is missing.'} 
          ${selectedResult?.image?.url ? '' : 'Image is missing.'} `,
          buttons: 'Go back',
          dangerMode: true,
        })
      }
    } else {
      _api.site.updateLive(id, live).then(async (_) => {
        await search()
        swal({
          title: 'Not Live!',
          icon: 'info',
          text: `${selectedResult?.name} is Not Live.`,
          buttons: 'Ok',
        })
      })
    }
    showSpinner(false)
  }

  const selectedSites = (index) => {
    searchResult[index].entity_type = 'site'
    selectedResult.push(searchResult[index])
    searchResult.splice(index, 1)
    dispatch({
      type: 'search', payload: {
        ...searchSite,
        selectedResult: selectedResult,
        searchResult: searchResult
      }
    })
  }

  const removeSelectedSites = (index) => {
    if (selectedEntity[index].section_index !== undefined && selectedEntity[index].entity_index !== undefined) {
      let section_index = selectedEntity[index].section_index
      let entity_index = selectedEntity[index].entity_index

      featured[section][section_index].edited = true
      featured[section][section_index].data[section].splice(entity_index, 1)
      featured[section][section_index].data[section].forEach((elem, index) => elem.entity_index = index)
      let sectionIndex = sectionResult.findIndex(elem => elem.section_index === section_index && elem.entity_index === entity_index)
      sectionResult.splice(sectionIndex, 1)
      sectionResult.forEach((elem, index) => elem.entity_index = index)
      dispatch({ type: 'featured', payload: { ...featured, [`${section}`]: featured[`${section}`] } })
      dispatch({ type: 'search', payload: { ...searchSite, [`${section}`]: sectionResult } })
    }
    else {
      let searchResultIndex = selectedResult.findIndex(elem => elem.id === selectedEntity[index].id)
      searchResult.push(selectedResult[searchResultIndex])
      selectedResult.splice(searchResultIndex, 1)
      dispatch({
        type: 'search', payload: { ...searchSite, selectedResult: selectedResult, searchResult: searchResult }
      })
    }

  }

  const selectedSitesDispatch = () => {
    showSpinner(true)
    if (selectedResult.length > 0) {
      let index = featured[`${section}`].length === 0 ? -1 : 0
      let data = {
        "start_date": start_date.slice(0, 10),
        "end_date": end_date.slice(0, 10),
        "section": `${section}`,
      }
      if (index === -1) {
        data.id = newTempId()
        data.data = {
          [`${section}`]: selectedResult
        }
        featured[`${section}`].push(data)
      }
      else {
        featured[`${section}`][index].edited = true
        featured[`${section}`][index].data[`${section}`] = [...featured[`${section}`][index].data[`${section}`], ...selectedResult]
      }
    }
    dispatch({ type: 'featured', payload: { ...featured, source: location.pathname } })
    dispatch({ type: 'search', payload: {} })
    showSpinner(false)
    history.push(`/featured/editor/${section}`)
  }

  return (
    <>
      <div className='mt-3 container-fluid'>
        <div className='row g-1'>
          <div className='col'>
            <div className='input-group'>
              <span className='input-group-text border-end-0'>
                <Icon name='search' />
              </span>
              <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                onChange={(event) => { setKeywordValue(event.target.value) }}
                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue); } }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className='col-auto'>
            <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue); }} />
          </div>
          <div className='col-auto'>
            <Button label='Add a Site' color='danger' icon='plus-white' onClick={() => history.push('/site/create')} />
          </div>
        </div>
      </div>

      <div className='mt-3 p-0 container-fluid'>
        <div className='card'>
          <div className='card-header bg-light p-2'>
            <div className='d-flex justify-content-between gap-2 align-items-end'>
              <div>
                <h4 className='mb-0 cursor-pointer text-capitalize' onClick={() => selectedSitesDispatch()}>{`${searchSite.title} - ${start_date === end_date ? start : `${start} - ${end}`}`}</h4>
                <h6 className='mb-0'>SITES</h6>
                <div className='small'>
                  <ResultCount page={page} take={take} count={paginationHits} />
                  {
                    label && label.map((elem, index) => {
                      return (
                        <span className='badge badge-search' key={`icon-${index}`}>
                          {elem} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { handleDeleteLabel(index) }} />
                        </span>
                      )
                    })
                  }
                </div>
              </div>

              <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />

              <div className='dropdown'>
                <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                  Sort by: {sort.name}
                </button>
                <ul className='dropdown-menu'>
                  {
                    columns.filter(x => x.sort === true).map((column, index) =>
                      <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                    )
                  }
                </ul>
              </div>
            </div>
          </div>

          <Spinner display={spinner}>
            <div className='table-responsive cnow-table'>
              <table className='table table-striped table-hover table-bordered'>
                <thead>
                  <tr className='bg-light'>
                    {
                      columns.filter(x => x.display === true).map((column, index) =>
                        <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                      )
                    }
                  </tr>
                </thead>
                <tbody>
                  <tr className='bg-dark'>
                    <th className='text-white' scope="col " colSpan="100%">
                      <div className='d-flex ' style={{ width: "94vw" }}>
                        <span className=''>
                          {`${searchSite.title} SITE ADDED`}
                        </span>
                        <span className='ms-auto text-lowercase'>{selectedEntity.length > 1 ? `${selectedEntity.length} results` : `${selectedEntity.length} result`}</span>
                      </div>
                    </th>
                  </tr>
                  {
                    selectedEntity?.map((item, index) => (
                      <tr key={`${item.id}-${index}`} >
                        <td>
                          <div className='row g-1'>
                            <div className='col-auto'>
                              <div className='form-check mt-1'>
                                <input className='form-check-input align-middle' type='checkbox' defaultChecked={true} name='select_site' value={item.id} onClick={() => { removeSelectedSites(index) }} />
                              </div>
                            </div>
                            <div className='col-auto'>
                              <div className='btn-list'>
                                <LinkButton size='sm' icon='edit' to={`/site/${item.id}/editor`} target="_blank" />
                                <PreviewButton size='sm' link={`site/${item.id}`} />
                                <Button size='live'
                                  icon={item.live === true ? 'live' : 'not-live'}
                                  color={item.live === true ? '' : 'danger'}
                                  onClick={() => updateLive(item.id, item.live === true ? false : true)} />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <ImageViewer url={item.image?.url} type='thumb' cssClass='thumb' />
                        </td>
                        <td className='fw-bold'>{item.name}</td>
                        <LocationList data={item.locations} />
                        <PeopleList data={item.people} />
                        <CategoryList data={item.categories} />
                        <ThemeList data={item.themes} />
                        <DateList data={item.dates} />
                        <MissingRequiredInfoList data={item} />
                        <td>{item.id}</td>
                        <TagList data={item.tags} />
                        <td>
                          <LongTextView lines={TABLE_ROWS + 1}>{item.description || (item?.dates && item?.dates?.filter((e) => e.description !== '')[0]?.description)}</LongTextView>
                        </td>
                        <PodcastList data={item.podcasts} />
                        <AwardList data={item.awards} />
                        <DesignationList data={item.designations} />
                        <Playlist data={item.playlists} />
                        <td></td>
                        <td></td>
                        <RelatedSiteList data={item.related_sites} />
                        <RelatedPeopleList data={item.related_people} />
                        <td></td>
                        <td></td>
                        <RelatedMapList data={item.maps} />
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))
                  }
                </tbody>
                <tbody>
                  <tr className='bg-dark'>
                    <th className='text-white' scope="col " colSpan="100%">
                      <div className='d-flex ' style={{ width: "94vw" }}>
                        <span className=''>
                          {`${searchSite.title} SITE AVAILABLE`}
                        </span>
                        <span className='ms-auto text-lowercase'>{searchResult.length > 1 ? `${searchResult.length} results` : `${searchResult.length} result`}</span>
                      </div>
                    </th>
                  </tr>
                  {
                    searchResult?.map((item, index) => (
                      <tr key={item.id}>
                        <td>
                          <div className='row g-1'>
                            <div className='col-auto'>
                              <div className='form-check mt-1'>
                                <input className='form-check-input align-middle' type='checkbox' name='select_site' value={item.id} onChange={(() => { selectedSites(index) })} disabled={resultFilters.limit && (selectedEntity.length >= resultFilters.limit) ? true : false} />
                              </div>
                            </div>
                            <div className='col-auto'>
                              <div className='btn-list'>
                                <LinkButton size='sm' icon='edit' to={`/site/${item.id}/editor`} target="_blank" />
                                <PreviewButton size='sm' link={`site/${item.id}`} />
                                <Button size='live'
                                  icon={item.live === true ? 'live' : 'not-live'}
                                  color={item.live === true ? '' : 'danger'}
                                  onClick={() => updateLive(item.id, item.live === true ? false : true)} />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <ImageViewer url={item.image?.url} type='thumb' cssClass='thumb' />
                        </td>
                        <td className='fw-bold'>{item.name}</td>
                        <LocationList data={item.locations} />
                        <PeopleList data={item.people} />
                        <CategoryList data={item.categories} />
                        <ThemeList data={item.themes} />
                        <DateList data={item.dates} />
                        <MissingRequiredInfoList data={item} />
                        <td>{item.id}</td>
                        <TagList data={item.tags} />
                        <td>
                          <LongTextView lines={TABLE_ROWS + 1}>{item.description || (item?.dates && item?.dates?.filter((e) => e.description !== '')[0]?.description)}</LongTextView>
                        </td>
                        <PodcastList data={item.podcasts} />
                        <AwardList data={item.awards} />
                        <DesignationList data={item.designations} />
                        <Playlist data={item.playlists} />
                        <td></td>
                        <td></td>
                        <RelatedSiteList data={item.related_sites} />
                        <RelatedPeopleList data={item.related_people} />
                        <td></td>
                        <td></td>
                        <RelatedMapList data={item.maps} />
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>

            <div className='d-flex justify-content-between m-3'>
              <div className='cols'>
                <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
              </div>
            </div>
          </Spinner>
        </div>
      </div>

      <ScrollToTop />
    </>
  );
};

export default FeaturedSites;