import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import Button from '../../components/buttons/default';
import LinkButton from '../../components/buttons/link';
import PreviewButton from '../../components/buttons/preview';
import Pagination from '../../components/pagination';
import ResultCount from '../../components/resultCount';
import ScrollToTop from '../../components/scrollToTop';
import Spinner from '../../components/spinner';
import ImageViewer from '../../components/viewers/image';
import TableHeader from '../../components/tableHeader';
import Icon from '../../components/viewers/icon';
import LocationList from '../../components/table/locationList'
import RelatedMapList from '../../components/table/relatedMapList';
import { TABLE_ROWS, checkUserTypeAndReturnIds } from '../../helpers';
import { useDispatch } from 'react-redux';
import LongTextView from '../../components/viewers/longText';
import { useSelector } from 'react-redux';
const columns = require('../../data/columns_map_overlays.json')
const _api = require('../../api')


const MapOverlays = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user)
    const history = useHistory();
    const [searchResult, setSearchResult] = useState([]);
    const [keywordValue, setKeywordValue] = useState('');
    const [keyword, setKeyword] = useState('');

    const take = 50;
    const [page, setPage] = useState(1);;
    const [sort, setSort] = useState(columns[0]);

    const [spinner, showSpinner] = useState(false);

    const search = useCallback(() => {
        // const from = 0
        let sortBoolean = true
        const params = {
            "bool": {
                "must": [
                    { "match": { "deleted": "false" } },
                    { "match": { "created_via": "ADMIN" } },


                ]
            }
        }
        if (keyword) {


            // Check if the keyword is a 4-digit year
            if (/^\d{4}$/.test(keyword)) {
                params.bool.must.push({
                    "bool": {
                        "should": [
                            {
                                "match": {
                                    "year": keyword
                                }
                            }
                        ]
                    }
                });
            } else {
                sortBoolean = false;
                params.bool.must.push({
                    "multi_match": {
                        "query": keyword,
                        "fields": [
                            "title",
                        ],
                        "fuzziness": 2,
                        "prefix_length": 2
                    }
                });
            }
        }

        const finalParams = {
            query: params
        }
        if (sortBoolean) {
            finalParams.sort = [{ "modified_date": { "order": "desc" } }]
        }


        showSpinner(true);


        _api.map_overlay.searchByElastic((page - 1) * 50, take, finalParams).then(response => {
            setSearchResult(response?.hits)
            showSpinner(false)
        })
        // eslint-disable-next-line 
    }, [page, keyword, user.id])

    useEffect(() => {
        search();
    }, [search]);

    const handleLiveNotLive = async (id) => {
        showSpinner(true)
        await _api.map_overlay.getById(id).then(async (overlay) => {
            overlay.live = !overlay.live;
            await _api.map_overlay.update(overlay).then((myOverlay) => {
                let messageStatus;
                let icon;
                if (myOverlay.live) {
                    messageStatus = `Not Live!`
                    icon = 'success'
                } else {
                    messageStatus = `Live!`
                    icon = 'info'
                }
                swal({
                    title: `Map is ${messageStatus}!`,
                    icon: icon,
                    text: `${myOverlay?.title} is now ${messageStatus}.`,
                    buttons: 'Ok',
                })
                dispatch({ type: 'entity', payload: myOverlay })
                showSpinner(false)
            })
        }).catch(() => { showSpinner(false) })
    }

    const deleteMapOverlay = (overlay) => {
        swal({
            title: `Delete Map`,
            text: `Are you sure that you want to delete ${overlay.name}?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then(async (status) => {
            _api.map_overlay.deleteById(overlay?.id).then(() => { search() })
        });
    }

    return (<>
        <div className='container-fluid mt-3'>
            <div className='row g-1'>
                <div className='col'>
                    <div className='input-group'>
                        <span className='input-group-text border-end-0'>
                            <Icon name='search' />
                        </span>
                        <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                            onChange={(event) => setKeywordValue(event.target.value)}
                            onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue) } }}
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div className='col-auto'>
                    <div className='btn-list'>
                        <Button label='Search' onClick={() => { setKeyword(keywordValue) }} />
                    </div>
                </div>
                <div className='col-auto'>
                    <div className='btn-list'>
                        <Button label='Add a Map Overlay' color='danger' icon='plus-white' onClick={() => history.push('/map_overlay/create')} />
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid mt-3 p-0'>
            <div className='card'>
                <div className='card-header bg-light p-2'>
                    <h4 className='mb-0'>Map Overlays</h4>
                    <div className='d-flex justify-content-between'>
                        <div className='small'>
                            <ResultCount page={page} take={take} count={searchResult?.total?.value} />
                            {keyword && <span className='badge badge-search'>
                                {keyword} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { setKeyword(''); setKeywordValue(''); }} />
                            </span>}
                        </div>
                        <div className='cols'>
                            <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
                        </div>
                        <div className=''>
                            <div className='dropdown'>
                                <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                                    Sort by: {sort.name}
                                </button>
                                <ul className='dropdown-menu'>
                                    {columns.filter(x => x.sort === true).map((column, index) =>
                                        <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Spinner display={spinner}>
                    <div className='table-responsive cnow-table'>
                        <table className='table table-striped table-hover table-bordered'>
                            <thead>
                                <tr className='bg-light'>
                                    {columns.filter(x => x.display === true).map((column, index) =>
                                        <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {searchResult && searchResult.hits && searchResult.hits.map((item, index) => {
                                    const maplist = item?._source?.related_maps?.map((cat) => cat) || []
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className='row g-1'>
                                                    <div className='col-auto'>
                                                        <div className='form-check mt-1'>
                                                            <input className='form-check-input align-middle' type='checkbox' name='select_map' value={item?._source.id} />
                                                        </div>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <div className='btn-list'>
                                                            <LinkButton size='sm' icon='edit' to={`/map_overlay/${item?._source.id}/editor`} />
                                                            <Button size='sm' icon='delete' onClick={() => deleteMapOverlay(item?._source)} />
                                                            <PreviewButton size='sm' link={`/historic-map/map_overlay/${item?._source.id}`} />
                                                            {item?._source.live ? <Button size='live' icon='live' onClick={() => handleLiveNotLive(item?._source.id)} />
                                                                : <Button size='live' icon='not-live' color='danger' onClick={() => handleLiveNotLive(item?._source.id)} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td><ImageViewer entityId={item?._source.image_id} entityType='image' type='thumb' cssClass='thumb' /></td>
                                            <td className='fw-bold'>{item?._source.year}</td>
                                            <td className='fw-bold'>{item?._source.title}</td>
                                            <LocationList data={item?._source.locations} />
                                            <td>{item?._source.item?._source ? `${item?._source?.item?._source?.length}x` : ''}</td>
                                            <td><LongTextView lines={TABLE_ROWS + 1}>{item?._source.description || (item?._source?.dates && item?._source?.dates?.filter((e) => e.description !== '')[0]?.description)}</LongTextView></td>
                                            <td>{item?._source.id}</td>
                                            <RelatedMapList data={maplist} />
                                            <td>{item?._source.edited_by}</td>
                                        </tr>
                                    );
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='d-flex justify-content-between m-3'>
                        <div className='cols'>
                            <Pagination page={page} take={take} count={searchResult?.total?.value} setPage={setPage} />
                        </div>
                    </div>
                </Spinner>
            </div>
        </div>
        <ScrollToTop />
    </>);
}
export default MapOverlays